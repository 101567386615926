<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle :title="'Cars'" :subtitle="'All cars available in your fleet'" />

    <CarsTableCard />

    <Footer />
  </div>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'
import Navbar from '@/components/Navbar.vue'
import CarModal from '@/components/CarModal.vue'
import Footer from '@/components/Footer.vue'
import CarsTableCard from '@/components/CarsTableCard.vue'
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'Cars',
  components: {
    Navbar,
    Footer,
    CarsTableCard,
    PageTitle
  },
  data() {
    return {
      isLoading: false,
      cars: []
    }
  },
  created() {
    this.getCars()
  },
  methods: {
    getCars() {
      this.isLoading = true

      httpClient
        .get('/cars')
        .then(res => {
          this.isLoading = false

          this.cars = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    confirmCarDelete(event, id) {
      event.stopPropagation()

      this.$buefy.dialog.confirm({
        title: 'Delete car',
        message: 'Are you sure you want to delete this car?',
        confirmText: 'Delete car',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCar(id)
      })
    },
    deleteCar(id) {
      this.isLoading = true

      httpClient
        .delete(`/cars/${id}`)
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car has been deleted.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.getCars()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    openCarModal(event, id) {
      event.stopPropagation()

      this.$buefy.modal.open({
        parent: this,
        component: CarModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          carId: id.toString()
        },
        events: {
          success: () => {
            this.getCars()
          }
        }
      })
    },
    goToCarDetail(item) {
      this.$router.push({ name: 'car', params: { carId: item.id.toString() } })
    }
  }
}
</script>

<style></style>
